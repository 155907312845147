if ($(window).width() < 960) {

} else {
    $('.post').addClass("invisible").viewportChecker({
        classToAdd: 'visible animated fadeIn',
        classToRemove: 'invisible',
        offset: 100
    });
    $('.post-up').addClass("invisible").viewportChecker({
        classToAdd: 'visible animated fadeInUp',
        classToRemove: 'invisible',
        offset: 100
    });
    $('.post-left').addClass("invisible").viewportChecker({
        classToAdd: 'visible animated fadeInLeft',
        classToRemove: 'invisible',
        offset: 100
    });
    $('.post-right').addClass("invisible").viewportChecker({
        classToAdd: 'visible animated fadeInRight',
        classToRemove: 'invisible',
        offset: 100
    });
    $('.post-down').addClass("invisible").viewportChecker({
        classToAdd: 'visible animated fadeInDown',
        classToRemove: 'invisible',
        offset: 100
    });
    $('.post-zoom').addClass("invisible").viewportChecker({
        classToAdd: 'visible animated zoomIn',
        classToRemove: 'invisible',
        offset: 100
    });
}
